<template>
  <div class="login content-centered">
    <div class="login__wrap">
      <form action="#" method="post" class="login__form">
        <h2 class="login__title login__title--forgot">Восстановление пароля</h2>
        <p class="login__forgot-text">Введите вашу почту, и мы вышлем вам инструкции по получению нового пароля</p>
        <label class="label-wrap login__form-control">
          <span class="label">Почта</span>
          <input v-model="email" type="email" class="input input--w100" placeholder="mail@gmail.com" />
          <FormErrorListPrinter :errorList="formErrors.email" />
        </label>
        <button @click="resetPassword" class="button login__submit" type="button">Подтвердить</button>
        <div class="login__register">Вспомнили пароль? <router-link :to="{ name: 'login' }">Войти</router-link></div>
      </form>
      <div class="login__logo">
        <img src="/img/logo.png" alt="Big Boss Reddit" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormErrorListPrinter from "../components/FormErrorListPrinter";

export default {
  name: "ForgotPassword",
  metaInfo: {
    title: "BIG BOSS REDDIT | Forgot password",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, maximum-scale=1",
      },
    ],
    link: [{ rel: "favicon", href: "public/favicon.ico" }],
  },
  data() {
    return {
      email: null,
      formErrors: {},
    };
  },
  components: {
    FormErrorListPrinter,
  },
  methods: {
    ...mapActions("RedditStore", {
      resetPasswordAction: "RESET_PASSWORD",
    }),
    async resetPassword() {
      let loader = this.$loading.show();
      this.formErrors = {};
      try {
        await this.resetPasswordAction({ email: this.email });
        this.$toast.success("Отправлен запрос на восстановление пароля");
        await this.$router.push({ name: "login" });
      } catch (e) {
        this.formErrors = e.response.data.errors;
        this.$toast.error("Ошибка");
      } finally {
        loader.hide();
      }
    },
  },
  mounted() {},
};
</script>

<style>
body {
  background-image: url("../../public/img/login-background.png");
  height: 100vh !important;
}
</style>
