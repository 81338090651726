<template>
  <p class="error-block">
    <span class="text-danger" v-for="(error, index) in errorList" :key="index">
      {{ error }}
    </span>
  </p>
</template>

<script>
export default {
  name: "FormErrorListPrinter",
  props: {
    errorList: {
      required: true,
    },
  },
};
</script>

<style scoped>
.error-block {
  width: 100%;
  margin: 0;
  padding: 0;
}
.text-danger {
  color: red;
}
</style>
